
import DxForm, {
  DxItem,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
  DxRequiredRule,
  DxEmailRule
} from 'devextreme-vue/form';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import notify from 'devextreme/ui/notify';
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';

import auth from "../auth";

const notificationText = 'Hemos enviado una liga por correo para recuperar su contraseña. Favor de verificar su correo.';

export default {
  components: {
    DxForm,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxRequiredRule,
    DxEmailRule,
    DxLoadIndicator
  },
  setup() {
    const router = useRouter();

    const loading = ref(false);
    const formData = reactive({
      email:""
    });

    async function onSubmit() {
      const { email } = formData;
      loading.value = true;

      const result = await auth.resetPassword(email);
      loading.value = false;

      if (result.isOk) {
        router.push("/login-form");
        notify(notificationText, "success", 2500);
      } else {
        notify(result.message, "error", 2000);
      }
    }

    return { 
      loading,
      formData,
      onSubmit
    }
  }
}
